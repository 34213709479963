import React from 'react';

import './paypal.less';

interface Props {
  isLoggedIn: boolean;
}

const ProgressBar = ({ isLoggedIn }: Props) => (
  <div className="progressBar w-full md:max-w-md m-0">
    <div className="progressBar-stepContainer">
      <div className="progressBar-stepNumberWrapper">
        <span className="progressBar-stepNumber isFilled">1</span>
      </div>
      <span className="progressBar-stepTitle">Create Account</span>
    </div>
    <div className="progressBar-stepContainer">
      <div className="progressBar-stepNumberWrapper">
        <span className={`progressBar-stepNumber ${isLoggedIn && 'isFilled'}`}>
          2
        </span>
      </div>
      <span className="progressBar-stepTitle">Checkout</span>
    </div>
    <div className="progressBar-stepContainer">
      <div className="progressBar-stepNumberWrapper">
        <span className="progressBar-stepNumber">3</span>
      </div>
      <span className="progressBar-stepTitle">Customize</span>
    </div>
    <div className="progressBar-lineContainer">
      <hr className="progressBar-line isFilled" />
      <hr className={`progressBar-line ${isLoggedIn && 'isFilled'}`} />
    </div>
  </div>
);

export default ProgressBar;
