import React, { Component } from 'react';

import { paymentMethods } from '../common/Constants';
import Paypal from './Paypal';

import './paypal.less';

interface Props {
  onApprove: (data: {
    [p: string]: any;
    zip: any;
    country: any;
    city: any;
    address1: any;
    state: any;
  }) => void;
  configuration: PaypalConfiguration;
  paymentMethodLabel: string;
  paymentMethodNonceLabel: string;
  firstNameLabel: string;
  lastNameLabel: string;
  planTelemetryName?: string;
}

export interface PaypalConfiguration {
  paypalClientId: string;
  paypalEnvironment: string;
  braintreeClientToken: string;
}

export default class PaypalCreateSubscription extends Component<Props> {
  handleApprove(payload) {
    const {
      paymentMethodLabel,
      paymentMethodNonceLabel,
      firstNameLabel,
      lastNameLabel,
    } = this.props;

    const shippingAddress = payload.details.shippingAddress || {};

    const data = {
      [paymentMethodLabel]: paymentMethods.PAYPAL,
      [paymentMethodNonceLabel]: payload.nonce,
      [firstNameLabel]: payload.details.firstName,
      [lastNameLabel]: payload.details.lastName,
      device_data: payload.deviceData,

      address1: shippingAddress.line1,
      city: shippingAddress.city,
      state: shippingAddress.state,
      country: shippingAddress.countryCode,
      zip: shippingAddress.postalCode,
    };

    this.props.onApprove(data);
  }

  onError(err) {
    console.error({ err });
    window.location.replace(
      `${window.location.origin}${window.location.pathname}?message=There has been an error processing your payment. Retry your request or try a different payment method. Contact customer support at support@storyblocks.com for more help.`
    );
  }

  render() {
    const { planTelemetryName } = this.props;
    const { paypalClientId, paypalEnvironment, braintreeClientToken } =
      this.props.configuration;

    return (
      <div className="ml-0 mr-0">
        <Paypal
          paypalClientId={paypalClientId}
          paypalEnvironment={paypalEnvironment}
          braintreeClientToken={braintreeClientToken}
          onApprove={this.handleApprove.bind(this)}
          planTelemetryName={planTelemetryName}
          step="signup"
          onError={this.onError.bind(this)}
        />
      </div>
    );
  }
}
