import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import { Button, ButtonColor } from '@videoblocks/storywind';

import { Organization } from '../../../common/javascripts/types/OrganizationTypes';
import {
  selectUserSignupCsmEmail,
  selectUserSignupEmail,
  selectUserSignupOrgs,
} from '../UserSignupSelectors';
import TeamCard from './TeamCard';

interface FoundYourTeamPageProps {
  goToConfirmation: (
    org: Organization,
    firstName: string,
    lastName: string,
    email: string
  ) => void;
}

const FoundYourTeamPage = (props: FoundYourTeamPageProps) => {
  const csmEmail = useSelector(selectUserSignupCsmEmail);
  const orgs = useSelector(selectUserSignupOrgs);
  const email = useSelector(selectUserSignupEmail);

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleGoBackToSignup = async () => {
    await fetch(`/api/join/logout-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    window.location.href = '/sign-up';
  };

  return (
    <div
      className="bg-white shadow-md rounded flex-column items-center md:px-16 py-8"
      data-testid="found-your-team-page"
    >
      <h3>Your team is on Storyblocks!</h3>
      <p>
        Join other members of your team to improve your workflow and use our
        Enterprise collaboration features.
      </p>
      <div className="self-start">
        <b>
          1. We found {orgs.length} potential{' '}
          {orgs.length > 1 ? 'teams' : 'team'} for you to join:
        </b>
      </div>
      <div className="w-full max-h-52 overflow-y-auto overflow-x-hidden mb-4">
        {orgs.map((o) => {
          return (
            <TeamCard
              key={o.id}
              org={o}
              rightContent={
                selectedOrg !== null && o.id === selectedOrg.id ? (
                  <div className="text-blue-500">Team Selected</div>
                ) : (
                  <Button
                    color={ButtonColor.Primary}
                    id={o.title}
                    onClick={() => {
                      setSelectedOrg(o);
                      Telemetry.increment(
                        'user.signup.found-your-team.select-org'
                      );
                    }}
                  >
                    Select team
                  </Button>
                )
              }
            />
          );
        })}
      </div>
      <div className="self-start mb-2">
        <b>2. Please enter your name below</b>
      </div>
      <div className="flex justify-between w-full mb-4">
        <div className="flex-column mr-4 w-1/2">
          <label>
            First Name
            <input
              id="firstName"
              disabled={selectedOrg === null}
              value={firstName}
              className="p-2 border border-solid border-gray-400 rounded"
              data-testid="first-name-input"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </label>
        </div>
        <div className="flex-column w-1/2">
          <label>
            Last Name
            <input
              id="lastName"
              disabled={selectedOrg === null}
              value={lastName}
              className="p-2 border border-solid border-gray-400 rounded"
              data-testid="last-name-input"
              onChange={(e) => setLastName(e.target.value)}
            />
          </label>
        </div>
      </div>
      <Button
        id="requestToJoin"
        color={ButtonColor.Primary}
        onClick={() => {
          props.goToConfirmation(selectedOrg, firstName, lastName, email);
        }}
        disabled={selectedOrg === null || firstName === '' || lastName === ''}
        className="w-full mb-4"
      >
        Request to join
      </Button>
      <p className="m-0">Don't want to join any of these teams?</p>
      <a
        role="button"
        tabIndex={0}
        className="mb-4 cursor-pointer"
        onClick={() => {
          handleGoBackToSignup();
          Telemetry.increment(
            'user.signup.found-your-team.signup-with-different-email'
          );
        }}
        onKeyDown={() => {
          handleGoBackToSignup();
          Telemetry.increment(
            'user.signup.found-your-team.signup-with-different-email'
          );
        }}
      >
        Sign up with a different email
      </a>
      <p className="m-0">Not sure which team to join?</p>
      <p>
        Contact <a href={`mailTo:${csmEmail}`}>{csmEmail}</a> for support
      </p>
    </div>
  );
};

export default FoundYourTeamPage;
