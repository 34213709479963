import React from 'react';

const ProcessingView = () => (
  <div className="paymentForm-columnContainer">
    <div className="paymentForm-column">
      <div className="paymentForm-processing-container">
        <div className="paymentForm-processing-header">
          Processing your payment
          <br />
          <div className="paymentForm-processing-subtext">
            Please do not refresh the page or click the "Back" or "Close" button
            of your browser
          </div>
        </div>
        <img
          alt="Loading"
          className="paymentForm-processing-indicator"
          src={`${__ASSETS_COMMON_IMAGES_URL__}/admin/loading.gif`}
          width={16}
          height={16}
        />
      </div>
    </div>
  </div>
);

export default ProcessingView;
