import AbstractAPI from '../../common/AbstractAPI';
import { HTTPMethod } from '../../common/Constants';

class UserAPI extends AbstractAPI {
  static async signup(parameters) {
    parameters = {
      ...parameters,
      'agreement-checkbox': 'on',
    };
    const options = this.buildFetchOptions(HTTPMethod.POST, parameters);

    return await this.request('/api/signup/user', options);
  }
}
export default UserAPI;
