import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Login from '../../Browse/containers/Login';
import { setIsLoggedIn } from '../../auth/AuthActions';
import { selectIsLoggedIn } from '../../auth/AuthSelectors';
import Logger from '../../common/Logger';
import HeaderLogo from '../HeaderLogo';
import ProgressBar from '../ProgressBar';
import {
  selectRedirectAfterRegister,
  selectCreateUserRoute,
} from './SignUpSelectors';

interface CreateAccountSignUpBlockProps {
  setIsFromSignUp: (isFromSignUp: boolean) => void;
}

export default function CreateAccountSignUpBlock({
  setIsFromSignUp,
}: CreateAccountSignUpBlockProps): ReactElement {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const redirectAfterRegister = useSelector(selectRedirectAfterRegister);
  const createUserRoute = useSelector(selectCreateUserRoute);

  const handleError = (error) => {
    Logger.error(
      `!!!Critical Alert: User cannot sign up for, or into, account: ${error}`
    );
  };

  const handleUserSignUp = async () => {
    const data = {
      signup_funnel: undefined,
    };

    data.signup_funnel = 'join';

    try {
      const res = await fetch(createUserRoute, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (res.status != 200) {
        handleError(res);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const toggleLogIn = async () => {
    await handleUserSignUp();
    dispatch(setIsLoggedIn());
    setIsFromSignUp(true);
  };

  if (!isLoggedIn) {
    return (
      <div className="flex-col pt-16 px-2.5 lg:px-28 md:w-1/2 flex items-center">
        <div className="w-full md:max-w-md">
          <HeaderLogo />
        </div>
        <ProgressBar isLoggedIn={false} />
        <Login successCallback={toggleLogIn} headerText={'Create Account'} />
      </div>
    );
  } else {
    window.location.href = redirectAfterRegister;
    return null;
  }
}
