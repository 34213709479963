import React from 'react';

type ActivationPageProps = {
  orgName: string;
  email: string;
  token: string;
};

const TrialUserActivationPage = (props: ActivationPageProps) => {
  const { orgName, email, token } = props;

  const title = () => {
    return orgName
      ? `You've been invited to join ${orgName} on Storyblocks`
      : `You've been invited to join Storyblocks`;
  };

  return (
    <div className="centeredFormBox" id="reset-page">
      <div className="centeredFormBox-header">
        <a href="/">
          <img
            alt="Storyblocks logo"
            className="centeredFormBox-companyLogo"
            src="/assets/common/images/logos/storyblocks.png"
            width={212}
            height={40}
          />
        </a>
      </div>

      <div className="centeredFormBox-formWrap">
        <div className="emailSignUpForm p-10 w-2/5">
          <h1 className="emailSignUpForm-title">{title()}</h1>

          <p className="pb-8">Please create a password.</p>

          <form
            action="/api/password/reset?isTrialUserFirstLogIn=true"
            method="POST"
            className="emailSignUpForm-form w-3/4"
            id="reset"
          >
            <div className="emailSignUpForm-formRow">
              <div className="emailSignUpForm-passwordLabels">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="emailSignUpForm-inputLabel">Password</label>
                <input
                  className="emailSignUpForm-showPasswordCheckbox"
                  type="checkbox"
                  id="show-password"
                  onChange={() => {
                    const passwordInput = document.getElementById(
                      'password'
                    ) as HTMLInputElement;
                    const confirmPasswordInput = document.getElementById(
                      'reset-confirm_password'
                    ) as HTMLInputElement;
                    if (passwordInput.type === 'password') {
                      passwordInput.type = 'text';
                      confirmPasswordInput.type = 'text';
                    } else {
                      passwordInput.type = 'password';
                      confirmPasswordInput.type = 'password';
                    }
                  }}
                />
                <label
                  className="emailSignUpForm-showPassword text-blue-700"
                  htmlFor="show-password"
                  role="button"
                >
                  Show Password
                </label>
              </div>

              <input
                type="password"
                name="password"
                id="password"
                className="emailSignUpForm-textField password placeholder-gray-600"
                required
                placeholder="At least 6 characters"
              />
            </div>

            <div className="emailSignUpForm-formRow">
              <label
                className="emailSignUpForm-inputLabel"
                htmlFor="reset-confirm_password"
              >
                Confirm password
              </label>
              <input
                type="password"
                name="password_confirmation"
                id="reset-confirm_password"
                className="emailSignUpForm-textField confirm_password"
                placeholder="At least 6 characters"
                equalto="#password"
              />
            </div>

            <input type="hidden" name="token" value={token} />

            <input type="hidden" name="email" value={email} />

            <p className="submit-button-wrapper">
              <button
                className="btn btn-primary"
                type="submit"
                id="reset-submit"
              >
                Activate Your Account
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TrialUserActivationPage;
