import { ReduxState } from '../../AppLoader/types';

export const selectOrderSummary = (state: ReduxState) =>
  state.signUp.orderSummary;
export const selectErrorMessage = (state: ReduxState) =>
  state.signUp.errorMessage;
export const selectIsAnnualSelected = (state: ReduxState) =>
  state.signUp.isAnnualSelected;
export const selectPlan = (state: ReduxState) => state.signUp.plan;
export const selectDownloadLimit = (state: ReduxState) =>
  state.signUp.downloadLimit;
export const selectIsFetchingOrderUpdate = (state: ReduxState) =>
  state.signUp.isFetchingOrderUpdate;
export const selectPlanUniqueIdSelector = (state: ReduxState) =>
  state.signUp.planUniqueIdSelector;
export const selectSelectedPlanUniqueId = (state: ReduxState) =>
  state.signUp.selectedPlanUniqueId;
export const selectPlanTelemetryName = (state: ReduxState) =>
  state.signUp.planTelemetryName;
export const selectCreateSubscriptionRoute = (state: ReduxState) =>
  state.signUp.createSubscriptionRoute;
export const selectCreateUserRoute = (state: ReduxState) =>
  state.signUp.createUserRoute;
export const selectPaymentToken = (state: ReduxState) =>
  state.signUp.paymentToken;
export const selectIsProcessingSubscriptionRequest = (state: ReduxState) =>
  state.signUp.isProcessingSubscriptionRequest;
export const selectPaymentMethodConfiguration = (state: ReduxState) =>
  state.signUp.paymentMethodConfiguration;
export const selectNeedsFinalConfirmation = (state: ReduxState) =>
  state.signUp.needsFinalConfirmation;
export const selectUpdatePaymentMethodRoute = (state: ReduxState) =>
  state.signUp.updatePaymentMethodRoute;
export const selectIsSignUpOnly = (state: ReduxState) =>
  state.signUp.isSignUpOnly;
export const selectRedirectAfterRegister = (state: ReduxState) =>
  state.signUp.redirectAfterRegister;
export const selectIsSmallBusinessPlan = (state: ReduxState) =>
  state.signUp.orderSummary.isSmallBusinessPlan;
