import React, { ReactComponentElement } from 'react';

function RadioDisabledCircle(props): ReactComponentElement<any> {
  return (
    <svg viewBox="-1 -1 26 26" fill="none" {...props}>
      <circle stroke="#000000" strokeWidth={1.5} cx={12} cy={12} r={12} />
    </svg>
  );
}

export default RadioDisabledCircle;
