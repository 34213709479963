import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import HeaderLogo from '../HeaderLogo';
import ProgressBar from '../ProgressBar';
import PaymentForm from './PaymentForm';
import PlanSummary from './PlanSummary';
import PlanToggle from './PlanToggle';
import {
  selectOrderSummary,
  selectIsSmallBusinessPlan,
} from './SignUpSelectors';

export default function PaymentSignUpBlock({ isFromSignUp }): ReactElement {
  const orderSummary = useSelector(selectOrderSummary);
  const isSmallBusinessPlan = useSelector(selectIsSmallBusinessPlan);
  const trackBuilderEvent = isFromSignUp ? 'visitorToMember' : 'userToMember';
  const planTitle = isSmallBusinessPlan
    ? `${orderSummary.planDisplayName} Plan - ${orderSummary.planUserCount} Users`
    : orderSummary.planDisplayName + ' Subscription';
  return (
    <div className="flex flex-col-reverse md:flex-row w-full">
      <div className="flex-col pt-16 px-2.5 lg:px-28 md:w-1/2 flex items-center">
        <div className="md:inline-block hidden w-full md:max-w-md">
          <HeaderLogo />
        </div>
        <div className="md:inline-block hidden pb-6 w-full md:max-w-md">
          <ProgressBar isLoggedIn />
        </div>
        <PaymentForm builderEvent={trackBuilderEvent} />
      </div>
      <section className="md:bg-gray-100 md:w-1/2 md:min-h-screen">
        <div className="mx-auto md:max-w-lg px-2.5">
          <div className="md:hidden inline-block mt-2.5">
            <HeaderLogo />
            <ProgressBar isLoggedIn />
          </div>
          <h1 className="text-2xl md:mt-16 mb-0">{planTitle}</h1>
          <h2 className="pr-1 block text-base inline-block text-blue-500 mt-0">
            {`${orderSummary.licenseName} License Included`}
          </h2>
          <PlanToggle />
          <PlanSummary />
        </div>
      </section>
    </div>
  );
}
