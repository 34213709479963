import React, { useState, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { selectIsLoggedIn } from '../../auth/AuthSelectors';
import CreateAccountSignUpBlock from './CreateAccountSignUpBlock';
import PaymentSignUpBlock from './PaymentSignUpBlock';
import { selectIsSignUpOnly } from './SignUpSelectors';

export default function SignUpContainer(): ReactElement {
  const [isFromSignUp, setIsFromSignUp] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isSignUpOnly = useSelector(selectIsSignUpOnly);

  return isLoggedIn && !isSignUpOnly ? (
    <PaymentSignUpBlock isFromSignUp={isFromSignUp} />
  ) : (
    <CreateAccountSignUpBlock setIsFromSignUp={setIsFromSignUp} />
  );
}
