import React from 'react';

const HeaderLogo = () => (
  <div className="pb-8">
    <a href="/">
      <img
        className="w-48 h-10"
        src="/assets/common/images/logos/unified/sb-logo.svg"
        alt="Storyblocks"
        width={150}
        height={32}
      />
    </a>
  </div>
);

export default HeaderLogo;
