import { RadioGroup } from '@headlessui/react';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import Logger from '../../common/Logger';
import RadioCheckIcon from '../RadioCheckIcon';
import RadioDisabledCircle from '../RadioDisabledCircle';
import { setIsAnnualSelected, setSelectedPlanUniqueId } from './SignUpActions';
import {
  selectIsAnnualSelected,
  selectOrderSummary,
  selectIsSmallBusinessPlan,
} from './SignUpSelectors';

const getBillingDescription = (
  formattedPrice: string,
  billingPeriod: string = 'Monthly',
  showBreakdown: boolean = false,
  breakdownFormattedPrice?: string,
  breakdownUnits?: string
) => {
  return `${formattedPrice} Billed ${billingPeriod} ${
    showBreakdown ? `(${breakdownFormattedPrice} ${breakdownUnits})` : ''
  }`;
};

export default function PlanToggle(): ReactElement {
  const orderSummary = useSelector(selectOrderSummary);
  const isAnnualSelected = useSelector(selectIsAnnualSelected);
  const isSmallBusinessPlan = useSelector(selectIsSmallBusinessPlan);
  const dispatch = useDispatch();

  const { annualPlan, monthlyPlan } = orderSummary;

  const handleToggle = (handleToggleSelectedPlanUniqueId: string) => {
    const newAnnualSelected =
      handleToggleSelectedPlanUniqueId === annualPlan.uniqueId;

    dispatch(setIsAnnualSelected(newAnnualSelected));
    dispatch(setSelectedPlanUniqueId(handleToggleSelectedPlanUniqueId));

    const telemetryName = isAnnualSelected
      ? 'pricing.billingToggle.annual'
      : 'pricing.billingToggle.monthly';

    Logger.accessTelemetry().increment(telemetryName);
  };

  return (
    <RadioGroup value={isAnnualSelected} onChange={handleToggle}>
      <div className="space-y-2">
        {annualPlan && (
          <RadioGroup.Option value="Annual Plan"></RadioGroup.Option>
        )}
        <RadioGroup.Option
          key={annualPlan.uniqueId}
          value={annualPlan.uniqueId}
          checked={isAnnualSelected}
          className={({ active }) =>
            twMerge(
              'bg-white relative rounded-lg ring-offset-2 px-5 py-4',
              active && 'ring-2 ring-offset-sky-300 ring-blue',
              isAnnualSelected && 'ring-2 ring-blue'
            )
          }
        >
          <>
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <div className="text-sm">
                  <RadioGroup.Label as="p" className={`font-bold text-xl`}>
                    Annual Plan
                  </RadioGroup.Label>
                  <RadioGroup.Description as="span" className={`inline`}>
                    <span>
                      {getBillingDescription(
                        annualPlan.formattedPrice,
                        'Annually',
                        true,
                        annualPlan.monthlyFormattedPrice,
                        isSmallBusinessPlan
                          ? 'per user, per month'
                          : 'per month'
                      )}
                    </span>
                    {annualPlan?.formattedAnnualDiscount && (
                      <span className="text-green-700 font-bold">
                        &nbsp;Save&nbsp;
                        {annualPlan.formattedAnnualDiscount}
                        /year
                      </span>
                    )}
                  </RadioGroup.Description>
                </div>
              </div>
              {isAnnualSelected ? (
                <div className="flex-shrink-0 text-white bg-blue">
                  <RadioCheckIcon className="w-6 h-6" />
                </div>
              ) : (
                <div className="flex-shrink-0">
                  <RadioDisabledCircle className="w-6 h-6" />
                </div>
              )}
            </div>
          </>
        </RadioGroup.Option>
        {/* MONTHLY */}
        {monthlyPlan && (
          <RadioGroup.Option
            key={monthlyPlan.uniqueId}
            value={monthlyPlan.uniqueId}
            checked={!isAnnualSelected}
            className={({ active }) =>
              twMerge(
                'bg-white relative rounded-lg ring-offset-2 px-5 py-4',
                active && 'ring-2 ring-offset-sky-300 ring-blue',
                !isAnnualSelected && 'ring-2 ring-blue'
              )
            }
          >
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label as="p" className={`font-bold text-xl`}>
                      Monthly Plan
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className={`inline`}>
                      <span>
                        {getBillingDescription(
                          monthlyPlan.formattedPrice,
                          'Monthly',
                          false
                        )}
                      </span>
                    </RadioGroup.Description>
                  </div>
                </div>
                {!isAnnualSelected ? (
                  <div className="flex-shrink-0 text-white bg-blue">
                    <RadioCheckIcon className="w-6 h-6" />
                  </div>
                ) : (
                  <div className="flex-shrink-0">
                    <RadioDisabledCircle className="w-6 h-6" />
                  </div>
                )}
              </div>
            </>
          </RadioGroup.Option>
        )}
      </div>
    </RadioGroup>
  );
}
