import React, { useState } from 'react';

import QuestionCircleIcon from '@videoblocks/react-icons/QuestionCircle';
import { Popper } from '@videoblocks/storywind';

interface Props {
  popoverCopy: string;
  bullets?: string[];
  color: string;
}

const QuestionPopover = (props: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { popoverCopy, bullets, color } = props;
  return (
    <Popper
      showContent={showTooltip}
      placement="top"
      popperClasses="bg-white text-sm rounded shadow-popover w-80 p-4"
      {...props}
      triggerElement={(referenceElement) => (
        <QuestionCircleIcon
          className={`h-4 w-4 ${color}`}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          ref={referenceElement}
        />
      )}
    >
      <div id="tooltip-download" className="p-1 text-xs">
        {popoverCopy}
        <ul>{bullets?.map((item, index) => <li key={index}>{item}</li>)}</ul>
      </div>
    </Popper>
  );
};
export default QuestionPopover;
