import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import CheckIcon from '@videoblocks/react-icons/Check';
import { LegacyLoadingSpinner as LoadingSpinner } from '@videoblocks/storywind';

import QuestionPopover from '../QuestionPopover';
import {
  selectDownloadLimit,
  selectIsAnnualSelected,
  selectIsFetchingOrderUpdate,
  selectOrderSummary,
  selectPlan,
  selectIsSmallBusinessPlan,
} from './SignUpSelectors';

export default function PlanSummary(): ReactElement {
  const orderSummary = useSelector(selectOrderSummary);
  const isAnnualSelected = useSelector(selectIsAnnualSelected);
  const plan = useSelector(selectPlan);
  const downloadLimit = useSelector(selectDownloadLimit);
  const isFetchingOrderUpdate = useSelector(selectIsFetchingOrderUpdate);
  const isSmallBusinessPlan = useSelector(selectIsSmallBusinessPlan);

  const selectedPlan = isAnnualSelected
    ? orderSummary.annualPlan
    : orderSummary.monthlyPlan;

  const annualRenewDate = new Date();
  annualRenewDate.setDate(annualRenewDate.getDate() + 365);
  const annualRenewDateString = annualRenewDate.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const monthlyRenewDate = new Date();
  monthlyRenewDate.setDate(monthlyRenewDate.getDate() + 30);
  const monthlyRenewDateString = monthlyRenewDate.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const subtotal = isAnnualSelected
    ? selectedPlan.formattedPrice
    : selectedPlan.monthlyFormattedPrice;

  return (
    <>
      <ul className="text-gray-800 mt-6 mb-0 list-none leading-7">
        {orderSummary.hasPremierePro && (
          <>
            <li>{'Adobe Creative Cloud Plugin'}</li>
          </>
        )}
        {isSmallBusinessPlan && (
          <>
            <li>{'Production Budget up to $10K'}</li>
          </>
        )}
        <li>{downloadLimit + ' of:'}</li>
        {plan.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>

      <hr className="total-divider" />
      <table className="w-full">
        <tbody>
          <tr>
            <td className="pb-2">Subtotal</td>
            <td className="text-right pb-2">{subtotal}</td>
          </tr>

          {orderSummary.showSalesTaxLine && (
            <tr>
              <td className="pb-2">
                Estimated sales tax &nbsp;
                <QuestionPopover
                  popoverCopy="Storyblocks is required to collect sales tax in certain tax jurisdictions per local law and regulations. Please contact customer support if you are a tax-exempt business."
                  color="fill-gray"
                />
              </td>
              <td className="text-right pb-2" data-cy="sales-tax-amount">
                {selectedPlan.formattedSalesTaxAmount}
              </td>
            </tr>
          )}

          <tr>
            <td>Total</td>
            <td className="text-right">
              {selectedPlan.taxedFormattedPrice}
              {isFetchingOrderUpdate && (
                <div className="total-loading-spinner">
                  <LoadingSpinner width="28px" />
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <hr className="total-divider" />
      <table className="w-full">
        <tbody>
          <tr>
            <td className="align-bottom pb-4">Payment Due</td>
            <td
              className="text-4xl font-bold text-right pb-4"
              data-cy="payment-due"
            >
              {selectedPlan.taxedFormattedPrice + ' '}
              {orderSummary.planCurrency !== 'USD' && orderSummary.planCurrency}
            </td>
          </tr>
          <tr>
            <td className="align-bottom pb-4">Renewal Date</td>
            <td className="text-right font-bold pb-4">
              {isAnnualSelected
                ? annualRenewDateString
                : monthlyRenewDateString}
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="text-right font-bold">
              <CheckIcon className="h-4 w-4 fill-blue" /> Cancel Anytime
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
