import { createAction } from 'redux-actions';

import UserAPI from '../containers/UserAPI';

export const TYPES = {
  SIGNUP_SUBMIT_STARTED: 'SIGNUP_SUBMIT_STARTED',
  SIGNUP_SUBMIT_FAILED: 'SIGNUP_SUBMIT_FAILED',
  SIGNUP_SUBMIT_COMPLETED: 'SIGNUP_SUBMIT_COMPLETED',
  SIGNUP_SET_FORM_VALUE: 'SIGNUP_SET_FORM_VALUE',
};

export const signUpStarted = createAction(TYPES.SIGNUP_SUBMIT_STARTED);
export const signUpFailed = createAction(TYPES.SIGNUP_SUBMIT_FAILED);
export const signUpCompleted = createAction(TYPES.SIGNUP_SUBMIT_COMPLETED);

export const signUp = (formValues) => {
  return async (dispatch) => {
    dispatch(signUpStarted());

    try {
      const response = await UserAPI.signup(formValues);
      // Shadow Org Redirect
      if (response.url.includes('/join/found-your-team')) {
        window.location = '/join/found-your-team';
      } else if (response.status === 201 || response.status === 200) {
        const json = await response.json;
        await dispatch(signUpCompleted(json.message));
        return response;
      }
    } catch (e) {
      dispatch(signUpFailed(e.message));
      return e;
    }
  };
};

export const setValue = createAction(TYPES.SIGNUP_SET_FORM_VALUE);
