import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';

import CreditCardForm from './CreditCardForm';
import { CreditCardConfiguration } from './SignUp/SignUpStateDefinition';
import { isEligibleForSalesTax, isTaxableCountry } from './salesTaxUtils';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface Props {
  onAddressUpdate: (postalCode: number, country: string) => void;
  disableSubmitPayment: boolean;
  configuration: CreditCardConfiguration;
  submitPayment: (FormData) => void;
  errorMessage?: string;
  buttonText: string;
}

interface State {
  formData: FormData;
  errors: FormErrors;
}

interface FormData {
  full_name?: string;
  address1?: string;
  city?: string;
  state: string;
  zip?: string;
  country: string;
  cc_number?: string;
  cc_exp_full?: string;
  cc_cvv?: string;
  how_did_you_hear_about_us?: string;
  how_did_you_hear_about_us_other?: string;
  'g-recaptcha-response'?: string;
  pin?: string;
  device_data?: string;
}

interface FormErrors {
  full_name?: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  cc_number?: string;
  cc_exp_full?: string;
  cc_cvv?: string;
  pin?: string;
}

class CreditCardCreateSubscription extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        full_name: '',
        address1: '',
        city: '',
        state: props.configuration.fieldLabels.defaultStateValue || '',
        zip: '',
        country: props.configuration.fieldLabels.defaultCountryValue || '',
        cc_number: '',
        cc_exp_full: '',
        cc_cvv: '',
        how_did_you_hear_about_us: '',
        how_did_you_hear_about_us_other: '',
        'g-recaptcha-response': '',
      },
      errors: {
        full_name: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        cc_number: '',
        cc_exp_full: '',
        cc_cvv: '',
        pin: '',
      },
    };

    this.receiveData = this.receiveData.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.setGoogleCaptcha = this.setGoogleCaptcha.bind(this);
    this.updateGoogleCaptcha = this.updateGoogleCaptcha.bind(this);
  }

  componentDidMount() {
    const { errorMessage, configuration } = this.props;

    if (!errorMessage && configuration.googleCaptchaKey) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${configuration.googleCaptchaKey}`;
      script.addEventListener('load', this.setGoogleCaptcha);
      document.body.appendChild(script);
    }
  }

  setGoogleCaptcha() {
    window.grecaptcha.ready(() => {
      // do request for recaptcha token
      // response is promise with passed token
      window.grecaptcha
        .execute(this.props.configuration.googleCaptchaKey, {
          action: 'set_recaptcha_token',
        })
        .then((token) => {
          // add token value to form
          const { formData } = this.state;
          formData['g-recaptcha-response'] = token;
          this.setState({
            formData,
          });
        });
    });
  }

  updateGoogleCaptcha(data) {
    const { formData } = this.state;
    formData['g-recaptcha-response'] = data;
    this.setState({
      formData,
    });
  }

  validateForm() {
    let isValid = true;

    const { errors, formData } = this.state;

    if (errors) {
      const isError = Object.values(errors).find((errorString) => {
        return errorString.length > 0;
      });
      isValid = !isError;
    }

    if (!formData.full_name && !errors.full_name) {
      errors.full_name = 'This field is required.';
      isValid = false;
    }

    if (
      !formData.zip &&
      !errors.zip &&
      (this.props.configuration.shouldShowZipFieldOnly ||
        this.props.configuration.shouldShowAddressFields)
    ) {
      errors.zip = 'This field is required.';
      isValid = false;
    }

    if (!formData.cc_number && !errors.cc_number) {
      errors.cc_number = 'This field is required.';
      isValid = false;
    }

    if (!formData.cc_exp_full && !errors.cc_exp_full) {
      errors.cc_exp_full = 'This field is required.';
      isValid = false;
    }

    if (!formData.cc_cvv && !errors.cc_cvv) {
      errors.cc_cvv = 'This field is required.';
      isValid = false;
    }

    if (
      !formData.pin &&
      !errors.pin &&
      this.props.configuration.shouldShowPinField
    ) {
      errors.pin = 'This field is required.';
      isValid = false;
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  onApprove(event) {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }
    const dataToSubmit = {};

    Object.keys(this.state.formData).forEach((field) => {
      if (this.state.formData[field]) {
        dataToSubmit[field] = this.state.formData[field];
      }
    });

    this.props.submitPayment(dataToSubmit);
  }

  receiveData(errors, fieldName, updatedValue) {
    if (!errors && (fieldName === 'zip' || fieldName === 'country')) {
      this.handleZipOrCountryChange(fieldName, updatedValue);
    }

    this.setState({
      errors: errors || this.state.errors,
      formData: {
        ...this.state.formData,
        [fieldName]: updatedValue,
      },
    });
  }

  handleZipOrCountryChange(fieldName, updatedValue) {
    const oldCountry =
      this.state.formData.country ||
      this.props.configuration.fieldLabels.defaultCountryValue;
    const oldZip = this.state.formData.zip;
    const zip = fieldName === 'zip' ? updatedValue : oldZip;
    const country = fieldName === 'country' ? updatedValue : oldCountry;

    const updateTax =
      isEligibleForSalesTax(oldCountry, oldZip) ||
      isEligibleForSalesTax(country, zip);
    const toggleTax = isTaxableCountry(oldCountry) != isTaxableCountry(country);

    if (updateTax || toggleTax) {
      this.props.onAddressUpdate(zip, country);
    }
  }

  render() {
    const { configuration } = this.props;
    return (
      <>
        <CreditCardForm
          onDataUpdated={this.receiveData}
          errors={this.state.errors}
          configuration={configuration}
        />
        {this.props.errorMessage && (
          <div className="paymentForm-errorMessage">
            {this.props.errorMessage}
          </div>
        )}
        {this.props.errorMessage && (
          <>
            <br />
            <ReCAPTCHA
              sitekey={configuration.googleCaptchaSecondaryV2Key}
              onChange={this.updateGoogleCaptcha}
            />
          </>
        )}
        <button
          type="submit"
          className="btn btn-primary w-full"
          onClick={this.onApprove}
          disabled={this.props.disableSubmitPayment}
          data-cy="complete-payment"
        >
          {this.props.buttonText}
        </button>
      </>
    );
  }
}

export default connect()(CreditCardCreateSubscription);
