import { builder } from '@builder.io/react';
import React, { ReactElement, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckoutCreateSubscription from '../CheckoutCreateSubscription';
import ExistingPaymentMethodCreateSubscription from '../ExistingPaymentMethodCreateSubscription';
import { triggerPurchaseAttemptTelemetry } from '../paymentMethodFormUtils';
import {
  setErrorMessage,
  setIsFetchingOrderUpdate,
  setIsProcessingSubscriptionRequest,
  setNeedsFinalConfirmation,
  setOrderSummary,
  setPaymentMethodConfiguration,
  setPaymentToken,
} from './SignUpActions';
import {
  selectCreateSubscriptionRoute,
  selectErrorMessage,
  selectIsFetchingOrderUpdate,
  selectIsProcessingSubscriptionRequest,
  selectNeedsFinalConfirmation,
  selectOrderSummary,
  selectPaymentMethodConfiguration,
  selectPaymentToken,
  selectPlanTelemetryName,
  selectPlanUniqueIdSelector,
  selectSelectedPlanUniqueId,
  selectUpdatePaymentMethodRoute,
} from './SignUpSelectors';

export default function PaymentForm({ builderEvent }): ReactElement {
  const orderSummary = useSelector(selectOrderSummary);
  const paymentToken = useSelector(selectPaymentToken);
  const planUniqueIdSelector = useSelector(selectPlanUniqueIdSelector);
  const selectedPlanUniqueId = useSelector(selectSelectedPlanUniqueId);
  const planTelemetryName = useSelector(selectPlanTelemetryName);
  const createSubscriptionRoute = useSelector(selectCreateSubscriptionRoute);
  const isFetchingOrderUpdate = useSelector(selectIsFetchingOrderUpdate);
  const isProcessingSubscriptionRequest = useSelector(
    selectIsProcessingSubscriptionRequest
  );
  const paymentMethodConfiguration = useSelector(
    selectPaymentMethodConfiguration
  );
  const errorMessage = useSelector(selectErrorMessage);
  const needsFinalConfirmation = useSelector(selectNeedsFinalConfirmation);
  const updatePaymentMethodRoute = useSelector(selectUpdatePaymentMethodRoute);

  const dispatch = useDispatch();

  const hasExistingPaymentMethod = !!paymentToken;

  const orderSummaryUpdateControllerRef = useRef<AbortController>(null); // Using class variable to handle mutations synchronously, see handleAddressUpdate

  const handleAddressUpdate = async (zipCode, country) => {
    const data = {
      plan_unique_id: orderSummary.defaultPlanUniqueId,
      monthly_plan_unique_id: orderSummary.monthlyPlan?.uniqueId,
      annual_plan_unique_id: orderSummary.annualPlan?.uniqueId,
      zip: zipCode,
      country: country,
      plan_user_count: orderSummary.planUserCount,
    };

    dispatch(setIsFetchingOrderUpdate(true));
    try {
      // Abort any ongoing previous requests
      orderSummaryUpdateControllerRef.current?.abort();
      orderSummaryUpdateControllerRef.current = new AbortController();
      const res = await fetch('/api/signup/order-summary', {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        signal: orderSummaryUpdateControllerRef.current.signal,
      }); // Throws AbortError error if aborted

      if (res.redirected) {
        window.location.href = res.url;
      }
      const json = await res.json();
      if (json.data) {
        dispatch(setOrderSummary(json.data.orderSummary));
        dispatch(setIsFetchingOrderUpdate(false));
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        // If aborted, there is a new request, so early return to keep isFetchingOrderUpdate true
        return;
      }
    }
    orderSummaryUpdateControllerRef.current = null;
  };

  const handleError = (errorMessage?: string) => {
    const newErrorMessage =
      errorMessage ||
      'Looks like this email address is in use by another account. Try logging in, signing in with Google, or use a different email.';
    dispatch(setIsProcessingSubscriptionRequest(false));
    dispatch(setErrorMessage(newErrorMessage));
  };

  const handleBuilderAnalytics = () => {
    builder.init('e7fb3d1e4da14573bd2a1edb7bfee5f1');
    const price = orderSummary?.annualPlan?.formattedPrice;
    const nPrice = Number(price.replace('$', ''));
    builder.trackConversion(nPrice);
    builder.track(builderEvent);
  };

  const handleSubmitPayment = async (
    data = {
      signup_funnel: undefined,
    }
  ) => {
    dispatch(setIsProcessingSubscriptionRequest(true));
    dispatch(setErrorMessage(null));

    data.signup_funnel = 'join';
    data[planUniqueIdSelector] = selectedPlanUniqueId
      ? selectedPlanUniqueId
      : orderSummary.defaultPlanUniqueId;

    if (orderSummary.planUserCount) {
      data['userLimit'] = orderSummary.planUserCount;
    }

    triggerPurchaseAttemptTelemetry(planTelemetryName);
    try {
      const res = await fetch(createSubscriptionRoute, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const json = await res.json();
      if (json.redirectTo) {
        handleBuilderAnalytics();
        window.location.replace(json.redirectTo);
      } else {
        handleError(json && json.message);
      }
    } catch (err) {
      handleError();
    }
  };

  const handlePaypalAgreement = async (data) => {
    const currentAnnualPlanTotal =
      orderSummary?.annualPlan?.taxedFormattedPrice;
    const { zip, country } = data;

    await handleAddressUpdate(zip, country);

    const updatedCurrentAnnualPlanTotal =
      orderSummary?.annualPlan?.taxedFormattedPrice;
    if (updatedCurrentAnnualPlanTotal === currentAnnualPlanTotal) {
      await handleSubmitPayment(data);
    } else {
      dispatch(setNeedsFinalConfirmation(true));
    }
  };

  const handlePaymentUpdate = async (paymentData) => {
    dispatch(setPaymentToken(paymentData.paymentToken));
    dispatch(
      setPaymentMethodConfiguration(paymentData.paymentMethodConfiguration)
    );

    const { country, postalCode } = paymentData.paymentToken.address;

    if (country && postalCode) {
      await handleAddressUpdate(postalCode, country);
    }
  };

  return hasExistingPaymentMethod ? (
    <ExistingPaymentMethodCreateSubscription
      onSubmitPayment={handleSubmitPayment}
      onPaymentUpdate={handlePaymentUpdate}
      disableSubmitPayment={isFetchingOrderUpdate}
      isProcessingSubscriptionRequest={isProcessingSubscriptionRequest}
      paymentMethodConfiguration={paymentMethodConfiguration}
      errorMessage={errorMessage}
      paymentToken={paymentToken}
      updatePaymentMethodRoute={updatePaymentMethodRoute}
    />
  ) : (
    <CheckoutCreateSubscription
      onSubmitPayment={handleSubmitPayment}
      onPaypalAgreement={handlePaypalAgreement}
      onAddressUpdate={handleAddressUpdate}
      disableSubmitPayment={isFetchingOrderUpdate}
      isProcessingSubscriptionRequest={isProcessingSubscriptionRequest}
      needsFinalConfirmation={needsFinalConfirmation}
      onCancelFinalConfirmation={() =>
        dispatch(setNeedsFinalConfirmation(false))
      }
      paymentMethodConfiguration={paymentMethodConfiguration}
      errorMessage={errorMessage}
      planTelemetryName={planTelemetryName}
    />
  );
}
