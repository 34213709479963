import React from 'react';
import { useSelector } from 'react-redux';

import CheckCircleIcon from '@videoblocks/react-icons/CheckCircle';
import { Button } from '@videoblocks/storywind';

import { Organization } from '../../../common/javascripts/types/OrganizationTypes';
import TeamCard from './TeamCard';

interface ConfirmationPageProps {
  org: Organization;
  continueToStoryblocks: () => void;
}

const ConfirmationPage = (props: ConfirmationPageProps) => {
  const csmEmail = useSelector((state) => state.userSignup.csmEmail);
  const { org, continueToStoryblocks } = props;
  return (
    <div className="bg-white shadow-md rounded flex-column items-center px-16 py-8">
      <h3>Your request has been sent!</h3>
      <p>
        Once the team's administrator approves your request, you'll get a
        confirmation email to activate your account.
      </p>
      <div>
        <b>
          In the meantime you can get started making folders and downloading
          watermarked previews of our content. Enjoy browsing!
        </b>
      </div>
      <div className="w-full mb-4">
        <TeamCard
          org={org}
          rightContent={
            <div className="text-green-500 flex">
              <CheckCircleIcon className="inline-block w-6 h-6 fill-current mr-4" />
              Request sent
            </div>
          }
        />
      </div>
      <Button
        id="continueToStoryBlocks"
        className="w-full mb-4"
        onClick={continueToStoryblocks}
      >
        Continue to Storyblocks
      </Button>
      <p className="m-0">Need to request access to a different team?</p>
      <p>
        Contact <a href={`mailTo:${csmEmail}`}>{csmEmail}</a> for support
      </p>
    </div>
  );
};

export default ConfirmationPage;
