import React from 'react';

import { Organization } from '../../../common/javascripts/types/OrganizationTypes';

interface TeamCardProps {
  org: Organization;
  rightContent: React.ReactNode;
}

const TeamCard = (props: TeamCardProps) => {
  const { org, rightContent } = props;
  return (
    <div className="flex border border-solid border-gray-300 rounded w-full px-4 py-2 my-2 justify-between items-center">
      <div className="flex">
        <div className="flex-column ml-4">
          <b>{org.title}</b>
          {org.seatsUsed} members
        </div>
      </div>
      {rightContent}
    </div>
  );
};

export default TeamCard;
