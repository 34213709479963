import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Button, TextInput } from '@videoblocks/storywind';

import { signUp } from '../actions/SignupActions';

interface Props {
  successCallback: () => void;
  headerText?: string;
  dispatch: Dispatch;
}

interface User {
  email?: string;
  password?: string;
}

interface State {
  user?: User;
  agreementCheckbox?: boolean;
  signupError?: string;
  invalidEmail?: boolean;
  invalidPassword?: boolean;
  validationMessage?: string;
  touchedEmail?: boolean;
  touchedPassword?: boolean;
}

// TODO: Convert to functional component
class Login extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: '',
        password: '',
      },
      signupError: '',
      invalidEmail: false,
      invalidPassword: false,
      validationMessage: '',
      agreementCheckbox: false,
      touchedEmail: false,
      touchedPassword: false,
    };
    this.validateFields = this.validateFields.bind(this);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });

    switch (name) {
      case 'email':
        this.setState({ touchedEmail: true });
        break;
      case 'password':
        this.setState({ touchedPassword: true });
        break;
      default:
        break;
    }
  };

  handleCheckbox = () => {
    this.setState({
      agreementCheckbox: !this.state.agreementCheckbox,
    });
  };

  isEmailValid = (email) => {
    const mailformat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!email.match(mailformat);
  };

  isPasswordValid = (password) => {
    return password.length > 5;
  };

  renderEmailField() {
    const { email } = this.state.user;

    const emailErrorMessage = 'Please enter a valid email address.';

    return (
      <div className="text-left mb-4 emailSignUpForm-inputLabel">
        <TextInput
          name="email"
          id="email"
          error={this.state.invalidEmail}
          className="emailSignUpForm-textField email placeholder-gray-600"
          required
          placeholder="e.g. me@email.com"
          autoComplete="email"
          value={email}
          onChange={this.handleChange}
          onBlur={this.validateFields}
          label="Email"
          data-cy="email"
        />
        {this.state.invalidEmail && (
          <label htmlFor="email" className="error">
            {emailErrorMessage}
          </label>
        )}
      </div>
    );
  }

  renderPasswordField() {
    const { password } = this.state.user;
    const passwordErrorMessage = 'Please enter at least 6 characters.';
    return (
      <div className="text-left mb-4">
        <TextInput
          type="password"
          name="password"
          id="password"
          error={this.state.invalidPassword}
          className="emailSignUpForm-textField password placeholder-gray-600"
          required
          placeholder="At least 6 characters"
          value={password}
          onChange={this.handleChange}
          onBlur={this.validateFields}
          label="Password"
          data-cy="password"
        />
        {this.state.invalidPassword && (
          <label htmlFor="email" className="error">
            {passwordErrorMessage}
          </label>
        )}
      </div>
    );
  }

  validateFields(event) {
    const { value, name } = event.target;

    switch (name) {
      case 'email':
        this.setState({
          invalidEmail: this.state.touchedEmail
            ? !this.isEmailValid(value)
            : false,
        });
        break;
      case 'password':
        this.setState({
          invalidPassword: this.state.touchedPassword
            ? !this.isPasswordValid(value)
            : false,
        });
        break;
      default:
        break;
    }
  }

  isValid() {
    const { email, password } = this.state.user;
    return this.isEmailValid(email) && this.isPasswordValid(password);
  }

  render() {
    const { headerText } = this.props;
    return (
      <div className="emailSignUpForm max-w-md">
        {this.state.signupError && (
          <div className="paymentForm-errorMessage">
            {this.state.signupError.includes('409')
              ? this.state.signupError
              : 'There has been an error processing your request. Please try again or contact customer support at support@storyblocks.com for more help.'}
          </div>
        )}
        {headerText && (
          <h1 className="text-2xl pr-32 my-8 w-full">{headerText}</h1>
        )}
        <a href="/social-signup/google">
          <img
            alt="Google Signin button"
            className="google-button w-full"
            src="/assets/common/images/googlebutton.png"
            width={852}
            height={88}
          />
        </a>
        <p className="dividing-header w-full">OR</p>
        <form
          id="email-form"
          className="emailSignUpForm-form w-full"
          onSubmit={this.submitForm}
          method="post"
        >
          {this.renderEmailField()}
          {this.renderPasswordField()}

          <div className="emailSignUpForm-tos items-start">
            <input
              id="agreement-checkbox"
              name="agreement-checkbox"
              type="checkbox"
              required={true}
              className="mt-1"
              checked={this.state.agreementCheckbox}
              onChange={this.handleCheckbox}
            />
            <label
              htmlFor="agreement-checkbox"
              className="emailSignUpForm-tosCheckBoxLabel"
            >
              I have read and accept the{' '}
              <a href="/terms-of-service">Terms of Service</a>,{' '}
              <a href="/privacy">Privacy Policy</a> and the License Agreement
              that applies to my plan:{' '}
              <a href="/license/individual-license">Individual License</a> /{' '}
              <a href="/license/business-license">Business License </a>
            </label>
          </div>

          <div className="submit-button-wrapper login">
            <Button
              className="btn btn-primary w-full"
              type="submit"
              disabled={!this.isValid()}
              data-cy="create-account"
            >
              Create Account
            </Button>
          </div>
        </form>
        <p className="emailSignUpForm-logIn">
          Already have an account? <a href="/login">Log in</a>
        </p>
      </div>
    );
  }

  submitForm = async (e) => {
    e.preventDefault();

    // @ts-ignore
    const result = await this.props.dispatch(signUp(this.state.user));
    if (result.status === 201 || result.status === 200) {
      this.props.successCallback();
    } else {
      console.error('ERROR: ' + result);
      this.setState({
        signupError: result.message,
      });
    }
  };
}

export default connect()(Login);
