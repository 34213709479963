import React from 'react';

const DeletedPaymentView = () => (
  <div className="step2-form text-center">
    <h4 className="paymentFormExistingPaymentMethod-header">No card on file</h4>
    <hr />
    <p>
      Please contact our support team at{' '}
      <a href="mailto:support@storyblocks.com">support@storyblocks.com</a> to
      add a credit card.
    </p>
  </div>
);

export default DeletedPaymentView;
