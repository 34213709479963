import React, { Component } from 'react';

import LockIcon from '@videoblocks/react-icons/Lock';

import { get } from '../common/utils';
import CreditCardCreateSubscription from './CreditCardCreateSubscription';
import PaypalCreateSubscription from './PaypalCreateSubscription';
import ProcessingView from './ProcessingView';
import { PaymentMethodConfiguration } from './SignUp/SignUpStateDefinition';

import './paypal.less';

interface Props {
  onSubmitPayment: (paypalPayment: object) => void;
  onPaypalAgreement: (data: object) => void;
  onAddressUpdate: (postalCode: number, country: string) => void;
  disableSubmitPayment: boolean;
  isProcessingSubscriptionRequest: boolean;
  needsFinalConfirmation: boolean;
  onCancelFinalConfirmation: () => void;
  paymentMethodConfiguration?: PaymentMethodConfiguration;
  errorMessage?: string;
  planTelemetryName?: string;
}

interface State {
  paypalPayment: any;
  showCCForm: boolean;
}

export default class CheckoutCreateSubscription extends Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      showCCForm: !get(
        this,
        'props.paymentMethodConfiguration.paypal.shouldShowPaypalButton',
        false
      ),
      paypalPayment: null,
    };
  }

  handlePaypalAgreement = (data) => {
    this.setState({ paypalPayment: data });
    this.props.onPaypalAgreement(data);
  };

  handleCancelFinalConfirmation = () => {
    this.props.onCancelFinalConfirmation();
    this.setState({ paypalPayment: null });
  };

  render() {
    const paymentSelectionFormContent = this.props
      .isProcessingSubscriptionRequest ? (
      <ProcessingView />
    ) : this.props.needsFinalConfirmation ? (
      this.renderFinalTotalConfirmation()
    ) : (
      this.renderPaymentSelectionView()
    );

    return <>{paymentSelectionFormContent}</>;
  }

  renderCCForm(creditCard, paypal) {
    return (
      <>
        {paypal.shouldShowPaypalButton && (
          <>
            <h3 className="font-bold text-2xl mb-4">Express Checkout</h3>
            <PaypalCreateSubscription
              configuration={paypal.configuration}
              firstNameLabel={paypal.firstNameLabel}
              lastNameLabel={paypal.lastNameLabel}
              paymentMethodLabel={paypal.paymentMethodLabel}
              paymentMethodNonceLabel={paypal.paymentMethodNonceLabel}
              onApprove={this.handlePaypalAgreement}
              planTelemetryName={this.props.planTelemetryName}
            />
            <div className="flex flex-nowrap justify-center items-center my-6">
              <div className="h-px bg-gray-400 w-full"></div>
              <div className="text-gray-400 text-sm">OR</div>
              <div className="h-px bg-gray-400 w-full"></div>
            </div>
          </>
        )}
        <h4 className="text-2xl">
          Credit Card Information
          <LockIcon className="fill-gray h-4 w-4 mx-2.5" />
          <span className="text-gray-700 text-sm font-normal">
            Secure Payment
          </span>
        </h4>
        <CreditCardCreateSubscription
          configuration={creditCard}
          submitPayment={this.props.onSubmitPayment}
          disableSubmitPayment={this.props.disableSubmitPayment}
          onAddressUpdate={this.props.onAddressUpdate}
          errorMessage={this.props.errorMessage}
          buttonText="Complete Payment"
        />
      </>
    );
  }

  renderPaymentSelectionView() {
    const { paypal, creditCard } = this.props.paymentMethodConfiguration;
    // Do not show "What is this" CVV popup on checkout experience regardless of country
    const creditCardWithoutCvvDescription = {
      ...creditCard,
      shouldShowCvvDescription: false,
    };
    return (
      <div id="step2-form" className="md:max-w-md">
        {this.renderCCForm(creditCardWithoutCvvDescription, paypal)}
      </div>
    );
  }

  renderFinalTotalConfirmation() {
    return (
      <div id="step2-form" className="step2-form step2-totalConfirmation">
        <h4>
          Your total has been updated with estimated sales tax. Would you like
          to continue with payment?
        </h4>
        <button
          type="submit"
          className="btn btn-primary step2-confirmUpdatedTotal"
          onClick={() => this.props.onSubmitPayment(this.state.paypalPayment)}
          onKeyDown={() => this.props.onSubmitPayment(this.state.paypalPayment)}
        >
          Continue With Payment
        </button>
        <a
          role="button"
          tabIndex={0}
          className="step2-cancelConfirmation"
          onClick={this.handleCancelFinalConfirmation}
          onKeyDown={this.handleCancelFinalConfirmation}
        >
          Go Back to Payment Information
        </a>
      </div>
    );
  }
}
