import '../../assets/common/javascripts/thirdparty/jquery/jquery.validate.min.js';
import '../../assets/common/javascripts/thirdparty/jquery/jquery.validate.additional-methods.min.js';
import '../../assets/common/javascripts/shared/ValidationRules.js';
import '../../assets/common/javascripts/shared/toggleExpand.js';
import '../../assets/common/javascripts/modules/signup/JoinStep1BMT.js';
import '../../assets/common/stylesheets/external-crutch.less';
import '../../assets/global/elements/flex.less';
import '../../assets/global/elements/buttons.less';
import '../../assets/global/settings/index.less';
import '../common/stylesheets/modules/signup/sign-up.less';
import '../../assets/common/stylesheets/modules/signup/centeredFormBox.less';
import '../../assets/common/stylesheets/modules/signup/doubleFormBox.less';
import '../common/stylesheets/modules/signup/payment-form.less';
import '../../assets/common/stylesheets/modules/signup/step1Form-redesign.less';
import '../../assets/common/stylesheets/modules/signup/generic-sign-up-page-frame.less';
import '../../assets/common/stylesheets/modules/widgets/progress-bar.less';
import '../global/vendor/tailwind/tailwind.less';
import '../../assets/common/stylesheets/modules/signup/user-lead-form-innards.less';
import '../../assets/common/stylesheets/modules/signup/user-lead-form-drop-shadow.less';
import '../../assets/common/stylesheets/modules/signup/step1Benefits.less';
import '../../assets/common/stylesheets/modules/widgets/full-width-background-widget.less';
import '../../assets/common/stylesheets/modules/popups/paymentMethodInfo.less';

// ------------------ piling on the assets from join offer for the ent license banner // TODO remove after s2ENT test
import '../../assets/common/stylesheets/modules/signup/step0WithBundles/header-with-tabs.less';
import '../../assets/global/vendor/bootstrap/bootstrap-xl.css';
import '../../assets/common/stylesheets/maintenance.css';
import '../../assets/common/stylesheets/modules/footer/footer.less';
import '../../assets/common/stylesheets/modules/widgets/toggle-radio.less';
import '../../assets/common/stylesheets/modules/notfound/not-found.less';
import '../../assets/videoblocks/stylesheets/components/mixins.less';
import '../../assets/common/stylesheets/modules/collections/collection-browse.less';
import '../../assets/common/stylesheets/modules/widgets/media-grid-container.less';
import '../../assets/common/stylesheets/modules/header/index.less';

//--------------------------------------------------------// TODO remove after s2ENT test ^^

import FoundYourTeamPageContainer from '../react/UserSignup/FoundYourTeamPageContainer';

// React entry point


import AppLoader from '../../assets/react/AppLoader/AppLoader';

import PopUpsContainer from '../react/PopUps/PopUpsContainer';
import GenericFAQ from '../react/common/components/GenericFAQ';
import SignUpContainer from '../react/PaymentMethodForms/SignUp/SignUpContainer';
import TrialUserActivationPage from "../react/Trials/TrialUserActivationPage";

AppLoader.load({
  routes: [
    {
      name: 'FoundYourTeam',
      path: '/join/found-your-team',
      component: FoundYourTeamPageContainer
    },
  ],
  auxApps: {
    'pop-up-container': PopUpsContainer,
    signUpContainer: SignUpContainer,
  },
  propBasedApps: {
    genericFrequentlyAskedQuestions: GenericFAQ,
    TrialUserActivationPage: TrialUserActivationPage
  },
});
