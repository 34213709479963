import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Telemetry } from '@videoblocks/kafka-rest-client';

import { Organization } from '../../common/javascripts/types/OrganizationTypes';
import { selectUser } from '../auth/AuthSelectors';
import ConfirmationPage from './components/ConfirmationPage';
import FoundYourTeamPage from './components/FoundYourTeamPage';
import { FoundYourTeamPageType } from './types/FoundYourTeamTypes';

const FoundYourTeamPageContainer = () => {
  const [currentPage, setCurrentPage] = useState<FoundYourTeamPageType>(
    FoundYourTeamPageType.FOUND_YOUR_TEAM_PAGE
  );

  const [org, setOrg] = useState<any>(null);
  const user = useSelector(selectUser);

  const addAccessRequestedRoleToUser = async (orgId: number) => {
    try {
      const data = {
        orgId,
      };

      const res = await fetch(`/api/request-org-access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        res.text().then((text) => {
          throw new Error(text);
        });
      }
    } catch (error: any) {
      toast('Could not request access to organization', {
        position: 'top-center',
        type: 'error',
        autoClose: 4000,
      });
    }
  };

  const sendEmailToUserAndAdmin = async (org: Organization) => {
    try {
      const data = {
        organizationId: org.id,
        userId: user.id,
      };

      const res = await fetch(`/api/join/found-your-team-send-email`, {
        method: 'POST',
        headers: {
          'content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const jsonResp = await res.json();
      if (!jsonResp) {
        console.error('Need to handle bad response from create-user-for-org');
      }
    } catch (error: any) {
      console.error(error); // TODO: how to handle messaging to user for this error??
    }
  };

  const updateFirstAndLastNames = async (fname: string, lname: string) => {
    try {
      const data = {
        fname,
        lname,
      };

      const res = await fetch(`/api/member/profile/update-profile`, {
        method: 'POST',
        headers: {
          'content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const jsonResp = await res.json();
      if (!jsonResp) {
        console.error('Need to handle bad response from create-user-for-org');
      }
    } catch (error: any) {
      toast('Could not update user name', {
        position: 'top-center',
        type: 'error',
        autoClose: 4000,
      });
    }
  };

  const goToConfirmation = async (
    org: Organization,
    fname: string,
    lname: string
  ) => {
    setOrg(org);

    await addAccessRequestedRoleToUser(org.id);
    await updateFirstAndLastNames(fname, lname);
    await sendEmailToUserAndAdmin(org);
    setCurrentPage(FoundYourTeamPageType.CONFIRMATION_PAGE);
  };

  return (
    <div className="flex justify-center pt-12">
      {currentPage === FoundYourTeamPageType.FOUND_YOUR_TEAM_PAGE ? (
        <FoundYourTeamPage goToConfirmation={goToConfirmation} />
      ) : (
        <ConfirmationPage
          org={org}
          continueToStoryblocks={() => {
            Telemetry.increment('user.signup.found-your-team.continue-to-sb');
            window.location.href = '/member/profile';
          }}
        />
      )}
    </div>
  );
};

export default FoundYourTeamPageContainer;

export const FoundYourTeamPortal = ({ children }) =>
  createPortal(children, document.querySelector('#found-your-team-portal'));
