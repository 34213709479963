import React, { Component } from 'react';

import { paymentMethods } from '../common/Constants';
import DeletedPaymentView from './DeletedPaymentMethodView';
import ProcessingView from './ProcessingView';
import {
  PaymentMethodConfiguration,
  PaymentToken,
} from './SignUp/SignUpStateDefinition';
import ViewOrUpdatePaymentMethod from './ViewOrUpdatePaymentMethod';

import './paypal.less';

declare global {
  interface Window {
    Intercom: any;
  }
}

interface Props {
  onSubmitPayment: (data?: object) => void;
  onPaymentUpdate: (data: object) => void;
  disableSubmitPayment: boolean;
  isProcessingSubscriptionRequest: boolean;
  paymentMethodConfiguration?: PaymentMethodConfiguration;
  errorMessage?: string;
  paymentToken: PaymentToken;
  updatePaymentMethodRoute: string;
}

interface State {
  shouldViewUpdatePage: boolean;
  wasPaymentMethodUpdated: boolean;
}

export default class ExistingPaymentMethodCreateSubscription extends Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      shouldViewUpdatePage: !props.paymentToken.isTokenValid,
      wasPaymentMethodUpdated: false,
    };
  }

  render() {
    let content;
    if (this.props.isProcessingSubscriptionRequest) {
      content = <ProcessingView />;
    } else if (this.props.paymentToken.isTokenDeleted) {
      content = <DeletedPaymentView />;
    } else if (this.state.shouldViewUpdatePage) {
      content = this.renderUpdatePage();
    } else {
      content = this.renderViewView();
    }

    return (
      <div className="paymentForm-existingPayment">
        <div className="paymentForm-columnContainer">
          <div className="paymentForm-column">
            <div
              className="step2ExistingPaymentMethodCreateSubscription"
              id="step2ExistingPaymentMethodCreateSubscription"
            >
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUpdatePage() {
    const { paymentToken, paymentMethodConfiguration } = this.props;

    if (paymentToken.isTokenDeleted) {
      return null;
    }
    return (
      <div className="step2-form">
        <h2 className="paymentFormUpdateExistingPaymentMethod-billingDetailsHeader">
          Update Your Payment
        </h2>
        <ViewOrUpdatePaymentMethod
          onPaymentUpdate={this.handlePaymentUpdate}
          errorMessage={''}
          shouldHideCancelButtonWhenInvalidToken={false}
          getJsonResponse={true}
          shouldForceUpdateViewOnly={true}
          paymentToken={paymentToken}
          paymentMethodConfiguration={paymentMethodConfiguration}
          showDisclaimerCheckbox={true}
          saveButtonText={'Update Payment Method'}
          showPaymentDetailsHeader={false}
        />

        {paymentToken.isTokenValid && (
          <div className="paymentForm-unifiedDetails">
            <a
              role="button"
              tabIndex={0}
              className="updatePaymentLink"
              onClick={this.toggleView}
              onKeyDown={this.toggleView}
            >
              &#8592; Use My Previous Payment Method
            </a>
          </div>
        )}
        {this.renderDisclaimer()}
      </div>
    );
  }

  getPaymentMethodString() {
    if (this.props.paymentToken?.paymentMethodType === paymentMethods.PAYPAL) {
      return '';
    }
    return ` ending in ${this.props.paymentToken.tokenDetails.creditCardLastFour}`;
  }

  handlePaymentUpdate = (data) => {
    this.setState({
      shouldViewUpdatePage: false,
      wasPaymentMethodUpdated: true,
    });
    this.props.onPaymentUpdate(data);
  };

  toggleView = () => {
    this.setState({
      shouldViewUpdatePage: !this.state.shouldViewUpdatePage,
    });
  };

  renderViewView() {
    return (
      <div
        id="paymentFormExistingPaymentMethod"
        className="step2-form paymentFormExistingPaymentMethod"
      >
        <h4 className="paymentFormExistingPaymentMethod-header">
          {this.state.wasPaymentMethodUpdated
            ? 'Your payment method has been updated'
            : 'Looks like we already have your payment information on file. Would you like to use it?'}
        </h4>
        <hr />
        <div className="paymentFormExistingPaymentMethod-line">
          <div className="paymentMethodImage">
            {this.getPaymentMethodImage()}
          </div>
          <p>
            <strong>{this.getPaymentMethodType()} </strong>
            {this.getPaymentMethodString()}
          </p>
        </div>
        <p>
          You are using this on your {this.props.paymentToken.subscriptionTitle}{' '}
          account
        </p>

        {this.renderErrors()}

        <button
          type="submit"
          className="btn btn-primary existingPaymentMethodButton"
          disabled={this.props.disableSubmitPayment}
          onClick={() => this.props.onSubmitPayment()}
        >
          Continue With This Payment Method
        </button>
        <a
          role="button"
          tabIndex={0}
          className="updatePaymentLink"
          onClick={this.toggleView}
          onKeyDown={this.toggleView}
        >
          Update Payment Method
        </a>
      </div>
    );
  }

  renderDisclaimer() {
    const { paymentToken } = this.props;

    switch (paymentToken.paymentMethodType) {
      case paymentMethods.PAYPAL:
        return (
          <div className="paymentForm-updatePaymentMethodMessage-container">
            <div className="paymentForm-updatePaymentMethodMessage">
              If you would like to change your payment method, please contact
              our customer support team at{' '}
              <a
                href="mailto:support@storyblocks.com"
                onClick={this.openIntercom}
              >
                support@storyblocks.com
              </a>
              .
            </div>
          </div>
        );
      case paymentMethods.CARD:
      default:
        return '';
    }
  }

  openIntercom(event) {
    event.preventDefault();
    if (window.Intercom) {
      window.Intercom('show');
      return false;
    }
    window.open('mailto:support@storyblocks.com');
    return true;
  }

  renderErrors() {
    return (
      this.props.errorMessage && (
        <div className="paymentForm-errorMessage">
          {this.props.errorMessage}
        </div>
      )
    );
  }

  getPaymentMethodType() {
    if (this.props.paymentToken?.paymentMethodType === paymentMethods.PAYPAL) {
      return '';
    }

    return this.props.paymentToken.tokenDetails.creditCardType || 'Credit Card';
  }

  getPaymentMethodImage() {
    let url = __ASSETS_COMMON_IMAGES_URL__ + '/creditcard.svg';
    let width = 38;
    let height = 24;

    if (this.props?.paymentToken?.paymentMethodType === paymentMethods.PAYPAL) {
      url = __ASSETS_COMMON_IMAGES_URL__ + '/paypal-logo-with-text.png';
      width = 100;
      height = 26;
    }

    return (
      <img src={url} alt="" role="presentation" width={width} height={height} />
    );
  }
}
