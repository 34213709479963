const TAX_VALIDATION_BY_COUNTRY = {
  US: {
    postalCodePattern: /^\s*(\d{5}|\d{9}|\d{5}-\d{4})\s*$/,
  },
  CA: {
    postalCodePattern: /^\s*([a-zA-Z]\d[a-zA-Z][ -]*\d[a-zA-Z]\d)\s*$/,
  },
  GB: {
    postalCodePattern:
      /^\s*^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$\s*$/i,
  },
  XI: {
    postalCodePattern: /^\s*(BT\d[A-Z0-9]?\s*\d[A-Z]{2})\s*$/i,
  },
};

export const isEligibleForSalesTax = (country: string, postalCode: string) => {
  const taxValidation = TAX_VALIDATION_BY_COUNTRY[country];
  return taxValidation && taxValidation.postalCodePattern.test(postalCode);
};

export const isTaxableCountry = (country: string) => {
  return !!TAX_VALIDATION_BY_COUNTRY[country];
};
